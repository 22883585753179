@font-face {
  font-family: $font;
  src: url("#{$fontPath}/PreciousSans-Bold.woff2") format("woff2"),
    url("#{$fontPath}/PreciousSans-Bold.woff") format("woff"),
    url("#{$fontPath}/PreciousSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $font;
  src: url("#{$fontPath}/PreciousSans-Black.woff2") format("woff2"),
    url("#{$fontPath}/PreciousSans-Black.woff") format("woff"),
    url("#{$fontPath}/PreciousSans-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $font;
  src: url("#{$fontPath}/PreciousSans-BlackItalic.woff2") format("woff2"),
    url("#{$fontPath}/PreciousSans-BlackItalic.woff") format("woff"),
    url("#{$fontPath}/PreciousSans-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: $font;
  src: url("#{$fontPath}/PreciousSans-DemiBoldItalic.woff2") format("woff2"),
    url("#{$fontPath}/PreciousSans-DemiBoldItalic.woff") format("woff"),
    url("#{$fontPath}/PreciousSans-DemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: $font;
  src: url("#{$fontPath}/PreciousSans-DemiBold.woff2") format("woff2"),
    url("#{$fontPath}/PreciousSans-DemiBold.woff") format("woff"),
    url("#{$fontPath}/PreciousSans-DemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $font;
  src: url("#{$fontPath}/PreciousSans-BoldItalic.woff2") format("woff2"),
    url("#{$fontPath}/PreciousSans-BoldItalic.woff") format("woff"),
    url("#{$fontPath}/PreciousSans-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: $font;
  src: url("#{$fontPath}/PreciousSans-Book.woff2") format("woff2"),
    url("#{$fontPath}/PreciousSans-Book.woff") format("woff"),
    url("#{$fontPath}/PreciousSans-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $font;
  src: url("#{$fontPath}/PreciousSans-Light.woff2") format("woff2"),
    url("#{$fontPath}/PreciousSans-Light.woff") format("woff"),
    url("#{$fontPath}/PreciousSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $font;
  src: url("#{$fontPath}/PreciousSans-BookItalic.woff2") format("woff2"),
    url("#{$fontPath}/PreciousSans-BookItalic.woff") format("woff"),
    url("#{$fontPath}/PreciousSans-BookItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: $font;
  src: url("#{$fontPath}/PreciousSans-LightItalic.woff2") format("woff2"),
    url("#{$fontPath}/PreciousSans-LightItalic.woff") format("woff"),
    url("#{$fontPath}/PreciousSans-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: $font;
  src: url("#{$fontPath}/PreciousSans-Medium.woff2") format("woff2"),
    url("#{$fontPath}/PreciousSans-Medium.woff") format("woff"),
    url("#{$fontPath}/PreciousSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $font;
  src: url("#{$fontPath}/PreciousSans-MediumItalic.woff2") format("woff2"),
    url("#{$fontPath}/PreciousSans-MediumItalic.woff") format("woff"),
    url("#{$fontPath}/PreciousSans-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: $font;
  src: url("#{$fontPath}/PreciousSans-ThinItalic.woff2") format("woff2"),
    url("#{$fontPath}/PreciousSans-ThinItalic.woff") format("woff"),
    url("#{$fontPath}/PreciousSans-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: $font;
  src: url("#{$fontPath}/PreciousSans-Thin.woff2") format("woff2"),
    url("#{$fontPath}/PreciousSans-Thin.woff") format("woff"),
    url("#{$fontPath}/PreciousSans-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $font;
  src: url("#{$fontPath}/PreciousSans-UltraBlack.woff2") format("woff2"),
    url("#{$fontPath}/PreciousSans-UltraBlack.woff") format("woff"),
    url("#{$fontPath}/PreciousSans-UltraBlack.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $font;
  src: url("#{$fontPath}/PreciousSans-UltraBlackItalic.woff2") format("woff2"),
    url("#{$fontPath}/PreciousSans-UltraBlackItalic.woff") format("woff"),
    url("#{$fontPath}/PreciousSans-UltraBlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
