* {
  box-sizing: border-box;
}
/* (C) NO SELECT + HIGHLIGHT COLOR */
/* * { user-select: none; } */
*::selection { background: none; }
/* *::-moz-selection { background: none; } */

body {
  margin: 0px;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  float: left;
  font-family: "PreciousSans";
  background: #fafafa;
  color: #626566;
  font-size: 0.875em;
  font-weight: 700;
  line-height: 22px;

  &.overlayIn {
    width: 100%;
    height: 100%;
    overflow: hidden;

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      background: rgba($color: $black, $alpha: 0.5);
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }
  }
}
#root {
  width: 100%;
  height: 100%;
  overflow: auto
}
.flexCC {
  display: flex;
  align-items: center;
  justify-content: center;
}
.textCenter {
  text-align: center;
}
p {
  margin: 0px 0px 20px 0px;
}
.textRed {
  color: $primary;
}
.mT20 {
  margin: 20px 0px 0px 0px;
  width: 100%;
  display: inline-block;
}
.mT15 {
  margin-top: 15px;
}
.mT25 {
  margin-top: 25px;
}
.mT30 {
  margin-top: 30px;
}
.mT50 {
  margin-top: 50px;
}
.mT100 {
  margin-top: 100px;
}
.infoText {
  position: relative;
  font-size: 10px;
  line-height: 18px;

  &:before {
    content: '*';
    font-size: 14px;
    font-weight: 500;
    margin-right: 3px;
    color: $primary;
  }
}
.pageHeader {
  background: url("../../images/texture.jpg") repeat-x;
  padding: 20px;
  text-align: center;

  @include mob-screen {
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.ct-arabic {
  direction: rtl;

  & h1, & h2, & h3, & h4, & h5, & h6 {
    text-align: right !important;
  }
  & p {
    text-align: right !important;
    &.welcomeSubHeadline {
      text-align: center !important;
    }
  }

  & .csCheckbox {
    padding-left: 0;
    padding-right: 30px;

    &__input {
      left: auto;
      right: 0px;
    }

    &__placeholder {
      left: auto;
      right: 0px;
    }
    & .formBlock {
      &__input {
        &.formBlock__select {
          text-align: right;

          & option {
            text-align: right;
          }
        }
      }
    }
  }

  & .tileGrid {
    &__in {
      &__img {
        margin-right: 0px;
        margin-left: 10px;
      }
    }
  }

  & .sidebar {
    &__List {
      &__item {
        padding-left: 0px;
        padding-right: 32px;

        &:before {
          left: auto;
          right: 0;
        }
      }
    }
  }

  & .flexInBlock {
    &__center {
      &:nth-child(2) {
        text-align: right;
      }
      & .alignCenter {
        text-align: center !important;
        width: 100%;
      }
    }
  }

  & .switch {
    &:before {
      left: auto;
      right: 0px;
    }

    &--active {
      &:before {
        right: auto;
        left: 0;
      }
    }
  }
   & .leftBlock {
    @include large-screen {
      padding-left: 0px;
      padding-right: 20px;
    }
  }

  & .formBlock {
    & h3 {
      font-size: em(26);
    }
  }

  & .headerBlock {
    h3 {
      font-size: em(26);
      text-align: center !important;
    }
  }

  & .splitBox {

    &__w80Out {
      padding-right: 0;
      padding-left: 10px;
    }
    & .textLabel {
      margin-right: 0px;
      //margin-left: 10px;
      font-size: em(16);
      &--type2 {
        font-size: em(16);
        font-weight: 500;
      }
    }
  }

  & .infoText {
    font-size: em(16);
    font-weight: 500;
  }
  .uploaded-doc-label {
    font-size: em(16);
    font-weight: 500;

    & p {
      font-size: em(16);
      font-weight: 600;
    }

    & ul {
      padding-right: 18px;

      & li {
        font-size: em(16);
        font-weight: 500;
      }
    }
  }
  & fieldset {
    & legend {
      font-size: em(16);
    }
  }
}

.wPanel {
  width: calc(100% - 40px);
  max-width: 600px;
  margin: 0px auto;
  margin-bottom: 40px;
  background: $white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgba($color: $black, $alpha: 0.3);
  border-bottom: 4px solid $secondary;

  &:not(.wPanel--detailPage) {
    @include large-screen {
      max-width: 1200px;
    }
  }

  &.paddingBonly {
    padding: 0px 0px 20px 0px;
  }

  &--detailPage {
    & h3 {
      font-size: em(18);
      letter-spacing: 0.45px;
      line-height: 24px;
      margin-bottom: 5px;
      width: 100%;
    }
  }

  &.ct-arabic {
    direction: rtl;

    & h2 {
      width: 100%;
    }

    & .arrowText {
      padding-left: 0;
      padding-right: 20px;

      &:before {
        left: auto;
        right: 0px;
        transform: rotate(180deg);
      }
    }

    & .csFile__placeholder .iconIn {
      margin-right: 0;
      margin-left: 10px;
    }

    & .instruction-item {
      padding-left: 0px;
      padding-right: 20px;
    }

    & .instruction-head:after,
    & .instruction-head:before {
      left: auto;
      right: -20px;
    }
    & .numberList {
      text-align: right;
    }
    & span.textBlock {
      text-align: right;
    }
    & .alignCenter {
      width: 100%;
      text-align: right !important;
      @include mob-screen {
        text-align: center !important;
      }
      @include large-screen {
        text-align: right !important;
      }
    }

    & .editable {
      padding-right: 0;
      padding-left: 35px;

      &__icon {
        right: auto;
        left: -28px;
      }
    }
    &.wPanel--detailPage {
      & h3 {
        font-size: 21px;
        line-height: 28px;
        margin-bottom: 15px;
      }
    }

    //& .splitBox {
    //
    //  &__w80Out {
    //    padding-right: 0;
    //    padding-left: 10px;
    //  }
    //  & .textLabel {
    //    margin-right: 0px;
    //    //margin-left: 10px;
    //    &--type2 {
    //      font-size: em(14);
    //      font-weight: 500;
    //    }
    //  }
    //}

  }

  &--loader {
      background: $primary;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  &.offBox {
    background: transparent;
    box-shadow: none;
    border: 0;
    /*max-width: 800px;*/

    & .shadowBlock {
      background: $white;
      margin-bottom: 20px;
      border-bottom: 4px solid $secondary;
      & .flexInBlock {
        @include large-screen {
          padding: 40px 20px;

        }
      }
    }
  }
}
.grid2 {
  @include large-screen {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 60px;
    margin-bottom: 50px;
    margin-top: 70px;
  }
}
.leftBlock {
  @include large-screen {
    padding-left: 20px;
  }
}
.more-instructions-sub {
  list-style-type: none;
}
.userDetailBox {
  margin-bottom: 20px;
  display: inline-block;
  width: 100%;
}
.iconText {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  &__icon {
    width: 19px;
    height: 19px;
  }

  &__Text {
    font-size: em(20);
    letter-spacing: 0.35px;
    font-weight: 700;
    width: calc(100% - 35px);
  }
}
.arrowText {
  margin: 0px 0px 17px 0px;
  padding-left: 20px;
  position: relative;
  font-size: em(14);
  letter-spacing: 0.35px;
  line-height: 22px;
  font-weight: 700;

  &:before {
    content: "";
    width: 11px;
    height: 11px;
    background: url("../../images/arrow-right-red.png") no-repeat;
    background-size: contain;
    position: absolute;
    left: 0px;
    top: 5px;
  }
}
.primaryLink {
  color: $primary;
  font-size: em(16);
  letter-spacing: 0.5px;
  line-height: 20px;
  font-weight: 700;
  padding-bottom: 5px;
  position: relative;
  text-decoration: none;
  margin: 0px 0px 20px 0px;
  display: inline-block;

  &:after {
    content: "";
    width: 100%;
    height: 2px;
    background: $primary;
    position: absolute;
    bottom: 3px;
    left: 0px;
  }
}
.numberList {
  margin: 0px 0px 20px 0px;
  padding: 0px 20px 0px 20px;
}
.buttomWrap {
  width: 100%;
  display: inline-block;

  & .btn {
    margin: 10px 0px;
    float: left;
  }
}

.iconMessageBox {
  width: 100%;
  display: inline-block;
  text-align: center;

  &__icon {
    display: block;
    margin: 20px auto;
  }

  &__title {
    font-size: 30px;
    letter-spacing: 0.75px;
    line-height: 39px;
    margin-bottom: 0px;
  }

  &__text {
    font-size: 14px;
    letter-spacing: 0.35px;
    line-height: 22px;
  }
}
.w20Out {
  width: calc(100% + 40px);
  margin: 0px -20px;
}
.lineTopBox {
  border-top: 1px solid #dfdfdf;
  padding: 20px;
}
.listInBox {
  margin: 5px 0px;

  & .arrowText {
    font-size: 14px;
    margin: 0px;
  }
}
.contentBlock {
  padding: 20px 20px 90px 20px;
  height: calc(100% - 75px);
  overflow: auto;

  & .wPanel {
    width: 100%;
  }
}
.flexCB {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.headerBlock {
  & h3 {
    font-size: em(18);
    letter-spacing: 0.45px;
    line-height: 24px;
    text-align: center;
    width: 100%;
  }
}
.imageHolder {
  width: 100%;
  border-radius: 20px;
  margin: 0px 0px 20px 0px;

  &__img {
    width: 100%;
  }
}
.splitBox {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  &__half {
    width: 50%;
  }

  &__w80Out {
    width: calc(100% - 80px);
    padding-right: 10px;
  }

  &__w80 {
    width: 80px;

    &.disabled {
      border: 1px solid #999999;
      border-radius: 18px;
      overflow: hidden;

      & .textContent {
        text-align: center;
        background: #eeeeee;
      }
    }
  }

  &__full {
    width: 100%;
  }

  & .textLabel {
    font-size: em(14);
    letter-spacing: 0.35px;
    line-height: 24px;
    font-weight: 500;
    margin-right: 5px;

    &--type2 {
      font-size: em(12);
      letter-spacing: 0.3px;
      line-height: 18px;
      color: $black;
      font-weight: 600;
    }
  }

  &--mBMd {
    margin-bottom: 30px;
  }

  & .textContent {
    color: $black;
    width: 100%;
    display: inline-block;
    word-break: break-word;
  }

  &--centered {
    justify-content: center;
  }
}
.csCheckboxWrap {
  width: 100%;
  padding: 10px;
  margin-bottom: 30px;
  max-width: 600px;
  margin: 0px auto;
}

.primaryTextAnchor {
  color: $primary;
  font-weight: 700;
}
.mnButtons {
  width: 100%;
  flex-wrap: wrap;
  position: fixed;
  height: auto;
  left: 0px;
  padding: 10px 20px;
  bottom: 0px;
  background: #f9f9f9;

  & .btn {
    min-width: 120px;
    margin: 5px 0px;
  }
}
.flexCC {
  &.mnButtons {
    & .btn {
      margin: 5px;
    }
  }
}
.mnButtons:not(.desktopBtn) {
  display: none;

  @include mob-screen {
    display: flex;
  }
}
.mnButtons.desktopBtn {
  position: static;
  padding: 0px;
  background: transparent;
  margin-top: 30px;

  @include mob-screen {
    display: none;
  }
}
.csSelect {
  width: auto;
  display: inline-block;
  height: 35px;
  border: 0px;
  border-bottom: 1px solid #dfdfdf;
  font-family: $font;
  font-size: em(14);
  letter-spacing: 0.35px;
  line-height: 24px;
  color: #000000;
  font-weight: 700;
  appearance: none;
  -webkit-appearance: none;
  padding-right: 20px;
  background: url("../../images/chevron-down.png") no-repeat;
  background-size: 12px;
  background-position: right;

  &--inFull {
    width: 100%;
  }

  &:focus {
    outline: 0;
  }
}
.editable {
  padding-right: 35px;
  position: relative;

  &__icon {
    width: 45px;
    height: 45px;
    position: absolute;
    right: -28px;
    top: -28px;
    background: url("../../images/110_edit.svg") no-repeat;
    background-size: contain;
  }
}

.greyBox {
  border: 1px solid rgba(223, 223, 223, 0.5);
  border-radius: 5px;
  background-color: $bodyBg;
  padding: 10px 20px;
  margin: 10px 0px;
  display: inline-block;
  width: 100%;
}
.formBlock {
  width: 100%;
  display: inline-block;
  margin: 10px 0px;

  &__label {
    display: block;
    font-size: 12px;
    letter-spacing: 0.3px;
    font-weight: 500;
    margin-bottom: 3px;
    color: $primaryText;
  }

  &__input {
    width: 100%;
    border: 0px;
    background: transparent;
    height: 35px;
    padding: 5px 0px;
    padding-bottom: 10px;
    font-size: 14px;
    letter-spacing: 0.35px;
    font-weight: bold;
    border-bottom: 1px solid #dfdfdf;
    white-space: nowrap;

    &:focus {
      outline: 0;
    }

    &.formBlock__select {
      appearance: none;
      -webkit-appearance: none;
      background: url("../../images/chevron-down.png") no-repeat;
      padding-right: 30px;
      background-position: right;
      background-size: 15px;
    }
  }
}

.box-holder.active {
  display: block;
}

.box-holder {
  display: none;
}
.editable-input {
  display: none;
}
.editable-input.active {
  display: show;
}
.disabled {
  pointer-events: none !important;
  opacity: 0.5 !important;
}

fieldset {
  margin: 5px 0px;
  width: 100%;
  display: inline-block;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  padding: 0px 15px;

  & legend {
    font-size: 12px;
  }

  & .formBlock__input {
    border-bottom: 0px;
  }

  &.error {
    border: 1px solid $red;
  }
}
.uploaded-doc-highlightbox {
  border: 1px dashed $secondary;
  padding: 5px 15px;
  margin: 10px 0px;
  position: relative;
  padding-right: 30px;
  display: inline-block;
  word-break: break-all;

  & .remove-file {
    width: 20px;
    height: 20px;
    right: 5px;
    top: calc(50% - 10px);
  }

  & .csModal__out:before, & .csModal__out:after {
    width: 15px;
    background: $primary;
    height: 2px;
    left: calc(50% - 7.5px);
    top: calc(50% - 1px);
  }
}
.camera-gallery-pop {
  & .remove-file {
    width: 20px;
    height: 20px;
    right: 5px;
    top: calc(50% - 10px);
  }

  & .csModal__out:before, & .csModal__out:after {
    width: 15px;
    background: $primary;
    height: 2px;
    left: calc(50% - 7.5px);
    top: calc(50% - 1px);
  }
}
.error-msg {
  font-weight: normal;
  line-height: 30px;
}
.error-msg-red {
  font-weight: normal;
  line-height: 16px;
  color: $primary;
}
.crs-outer {
  padding: 10px 0px;
}
.adnl-outer {
  padding: 5px 0px;
}
.crs-outer .textLabel,.adnl-outer .textLabel {
  font-size: 0.875em;
  letter-spacing: 0.35px;
  line-height: 24px;
  font-weight: 500;
  margin-right: 5px;
}
.crs-outer .textContent, .adnl-outer .textContent {
  color: #000000;
  width: 100%;
  display: inline-block;
  word-break: break-word;
}
.csModal.flexCol {
  flex-direction: column;
  text-align: center;
  z-index: 3;
}
.msgCntnr p {
  color: #ffffff;
  margin-top: 12px;
}
.pageHeader.hasCrossOver--lg + .wPanel.redientCheckPanel {
  margin-top: -100px;
}
.version {
  position: absolute;
  right: 35px;
  top: 5px;
  color: #ffffff;
  font-size: 11px;
  font-style: normal;
}
html,
body {
  overscroll-behavior-y: contain;
}
.more-instructions-sub.hide,
.panelWrapper.hide{
  display: none;
}
.more-instructions-sub.show,
.panelWrapper.show{
  display: block;
}
.more-instructions-sub {
  & a {
    margin-bottom: 0px;
  }
}
.instruction-item {
  padding-left: 20px;
}
.instruction-head {
  position: relative;

  &:before, &:after {
    content: '';
    width: 9px;
    height: 2px;
    background: #e1273e;
    position: absolute;
    left: -20px;
    top: 10px;
    transition: 0.3s linear;
  }

  &:after {
    top: 10.5px;
    transition: 0.3s linear;
    transform: rotate(90deg);
  }

  &.open {
    &:after {
      transition: 0.3s linear;
      transform: rotate(0deg);
    }
  }
}
.more-instructions-sub {
  padding: 0px;
  margin: 0px 0px 20px 0px;
}

.restrict,
h1,
h2,
h3,
p,
li,
a,
button,
.btn,
.gesture-ins-cont,
.iconText__Text,
.csFile__placeholder__text,
.textLabel,
.textContent,
.csCheckbox__text,
legend,.error-msg,
.tblock__text,
.switch__text,
.error-msg,
label,
.csBtnFile___inputIn,
.uploaded-doc-label,
.iconIn,
img{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.restrict::-moz-selection,
h1::-moz-selection,
h2::-moz-selection,
h3::-moz-selection,
li::-moz-selection,
a::-moz-selection,
label::-moz-selection,
button::-moz-selection,
.btn::-moz-selection,
.gesture-ins-cont::-moz-selection,
.iconText__Text::-moz-selection,
.textLabel::-moz-selection,
.textContent::-moz-selection,
.csFile__placeholder__text::-moz-selection,
.csCheckbox__text::-moz-selection,
.error-msg::-moz-selection,
legend::-moz-selection,
.tblock__text::-moz-selection,
.switch__text::-moz-selection,
.error-msg::-moz-selection,
.csBtnFile___inputIn::-moz-selection,
.uploaded-doc-label::-moz-selection,
.iconIn::-moz-selection,
p::-moz-selection {
  background: none;
}

.grid2masonry {
  max-width: 1200px;
  margin: 0 auto;

  & .box-holder {
    width: 100%;

    & .wPanel {
      max-width: none;
      overflow: hidden;
    }
  }
}
.csModal {
  & .wPanel {
    max-width: 900px;
    padding: 0px 0px 30px 0px;
  }
}
.shadowBlock {
  width: 100%;
  border-radius: 10px;

  &__head {
    background: $primaryGradient;
    padding: 10px 20px;
    border-radius: 10px;
    border-bottom: 4px solid $secondary;
    text-align: center;
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__img {
      max-width: 150px;
      display: none;

      @include mob-screen {
        display: block;
      }
    }
  }
}
.flexInBlock {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mob-screen {
    flex-direction: column;
  }

  &__center {
    max-width: 50%;

    @include mob-screen {
      width: 100%;
      margin: 10px 0px;
      max-width: none;
    }

    &:first-child {
      text-align: right;

      @include mob-screen {
        text-align: center;
      }
    }

    &:nth-child(2) {
      text-align: left;

      @include mob-screen {
        text-align: center;
      }
    }

    & p {
      margin-bottom: 0;
    }

  }

  &__title {
    font-size: 36px;
    text-align: left;

    @include mob-screen {
      font-size: 28px;
      line-height: 28px;
      text-align: center !important;
    }
  }
}
.tqImage {
  width: 150px;
  margin: 0px 40px;

  @include mob-screen {
    margin: 0px;
    width: 150px;
  }

  &.smImage {
    width: 80px;

    @include mob-screen {
      width: 80px;
    }
  }
}
.qrCodeWrapper {
  margin: 0px 30px;
}


.liteHead {
  width: 100%;
  text-align: center;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 36px;
  margin: 20px 0px 10px 0px;
}
.tileGrid {

  @include large-screen {
    margin: 20px 0px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
  }

  &__in {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 10px 0px;

    @include large-screen {
      flex-direction: column;
      text-align: center;
      box-shadow: 0px 0px 12px -4px #00000070;
      padding: 30px;
      border-radius: 10px;
    }

    &__img {
      width: 40px;
      margin-right: 20px;

      @include large-screen {
        margin-bottom: 20px;
        //min-height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      & img {
        width: 100%;
        float: left;
        max-height: 45px;
      }
    }

    &__content {
      width: calc(100% - 60px);

      @include large-screen {
        width: 100%;
      }

      & p {
        margin: 0px;
      }
    }
  }
}

.animativeHead {
  margin: 0px 40px;
}
.textBlock {
  display: block;
  text-align: left;
  margin: 5px 0px;
}
.ocr-details p {
  text-align: left;
}
.msgCntnr.ct-arabic {
  direction: rtl;
}
.ct-arabic .ocr-details p {
  text-align: right;
}
.salaryTable,.salaryTable tr,.salaryTable td {
  border: 1px solid #f1f1f1;
  border-collapse: collapse;
  padding: 5px;
}
.salaryDtls {
  margin: 10px 0px 20px 0px;
}

.select {
  max-height: 150px;
  overflow: auto;
  margin-bottom: 20px;

  &-option {
    width: 100%;
    background: #f5f5f5;
    padding: 5px 10px;
    border-bottom: 1px solid #dddddd;
  }
}
.sidebar--active.ct-arabic {
  right: 0px;
  left: auto;
}
.ct-arabic .iconIn--hamburger {
  margin-left: 5px;
  margin-right: 0px;
}
.ct-arabic .cameraOverlay__head__text {
  margin-right: 5px;
}
.pdfContainer {
  width: 100%;
  height: 100%;
  padding: 0px;
  max-width: 100%;
  text-align: center;
}
.tncModal {
  padding: 0px;
  width: 100%;
  height: 100%;
  display: block;
}
.tncModal .csModal__out {
  z-index: 100;
}
.tncModal .csModal__out:before,
.tncModal .csModal__out:after {
  background: #000000;
}
.countrySearchInput {
  position: relative;
  margin-bottom: 10px;

  & input {
    border: 1px solid #dfdfdf;
    padding: 5px 35px 5px 10px;
  }

  & .iconIn {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.iconIn--search {
  background: url('../../images/Search.svg') no-repeat center;
  background-size: contain;
}
.select-item {
  padding: 5px 0px;
}

.countrySearchList {
  height: calc(100% - 35px);
  overflow: auto;
}
.btn.disabled {
  background: #ccc;
}
fieldset.no-border {
  border: none;

  margin-top: 10px;
  margin-bottom: 20px;
}
fieldset.no-border input {
  border-bottom: 1px solid #cccccc;
  font-size: 14px;
  letter-spacing: 0.35px;
  font-weight: 500;
  text-align: center;
}
.otp-timer {
  color: #f20000;
  font-size: 13px;
  font-weight: 500;
  padding-left: 15px;
  @include mob-screen {
    padding-left: 0px;
  }
}
.regenerate-otp {
  margin-bottom: 20px !important;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 500;
  color: #f20000;
  padding-left: 15px;
  @include mob-screen {
    padding-left: 0px;
  }
}
.otp-sent-msg {
  margin-bottom: 30px !important;
  font-size: 14px;
  font-weight: 500;
}
.apiBlock input,
.apiBlock textarea,
.apiBlock p,
.apiBlock label {
  user-select: auto !important;
}
.apiBlock ::selection {
  background: #cccccc !important;
}
span.info {
  background: #ccc;
  border-radius: 20px;
  padding: 0 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  margin-right: 5px;
}
.pdfContainer canvas {
  width: auto !important;
  height: calc(100% - 40px) !important;
}

.btnNextPdf, .btnPrevPdf {
  font-weight: 600;
  font-size: 20px;
  border: 0;
  background: rgba(186, 176, 176, 0.35);
  border-radius: 5px;
}
.btnNextPdf {
  margin-left: 30px;
}
.btnPrevPdf {
  margin-right: 30px;
}
.pageBlock{
  width: 100%;
  text-align: center;
  position: fixed;
  bottom: 1%;
  z-index: 10;
}
.crsBlock {
  position: relative;
}
.delete-icon {
  background: url("../../images/25_minus.svg") no-repeat;
  background-size: contain;
  position: absolute;
  right: 0px;
  top: -5px;
  width: 22px;
  height: 22px;
  display: none;
}
.qrhead {
  line-height: 40px;
}
ul.qrInstructions {
  padding: 0px;
  list-style: disc inside none;
}
.crs_checkbox {
  width: 100%;
  margin: 5px 0px;
}
.haveChild {
  margin-bottom: 0px;
}
.haveParent {
  padding-top: 0px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb30 {
  margin-bottom: 20px;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  background: linear-gradient(to bottom, #ef2a42 0, #bd2234 100%);
}
.react-datepicker {
  font-family: "Arial" !important;
  font-size: 14px !important;
  letter-spacing: 0.35px !important;
  font-weight: normal !important;
}
.inputHeadline {
  margin-top: 10px;
  font-weight: 600 !important;
  font-size: 12px !important;
}
.monthpicker {
  float: left;

}
.monthpicker_from.monthpicker_En {
  margin-right: 2%;
  width: 48%;
}
.monthpicker_to.monthpicker_Ar {
  margin-right: 2%;
  width: 48%;
}
.splitBox-.input-half {
  width: 50%;
  float: left;
}
.block_headline {
  color: #ffffff;
  text-shadow: none;
}
.otpInput {
  -webkit-text-security: disc !important;
}