// COLORS
$primary: #ed3e42;
//$primaryGradient: linear-gradient(
//  180deg,
//  #f13c44 0%,
//  #d4263a 51%,
//  #bf2337 100%
//);
$primaryGradient: linear-gradient(to bottom,#ef2a42 0,#bd2234 100%);
$secondary: #ffba07;
$white: #ffffff;
$bodyBg: #fafafa;
$black: #000000;
$primaryText: #626566;
$secondaryText: $black;
$red: #f20000;

// WIDTH

// MISC

$fontPath: "../../../fonts";
$font: "PreciousSans";
