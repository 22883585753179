.csBtnFile {
  width: 100%;
  display: inline-block;
  position: relative;
  margin: 10px 0px;

  &___inputIn {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    position: absolute;
    left: 0px;
    top: 0px;
    cursor: pointer;
    opacity: 0;
    z-index: 1;
  }
  & .btn {
    margin: 0px;
    & .iconIn {
      width: 16px;
      height: 16px;
      background: url("../../../images/record.png") no-repeat;
      background-size: contain;
    }
  }
}
