.csCheckbox {
  width: auto;
  display: inline-block;
  position: relative;
  padding-left: 30px;

  &__input {
    width: 15px;
    height: 15px;
    margin: 0px;
    padding: 0px;
    position: absolute;
    left: 0px;
    top: 4px;
    opacity: 0;
    z-index: 1;
    cursor: pointer;

    &:checked {
      & + {
        & .csCheckbox__placeholder {
          border: 1px solid $primary;
          transition: 0.3s linear;
          background: $primary;

          &:after {
            transform: scale(1);
            transition: 0.3s linear;
          }
        }
      }
    }
  }

  &__placeholder {
    width: 15px;
    height: 15px;
    position: absolute;
    left: 0px;
    top: 4px;
    border: 1px solid #a5a3a3;
    transition: 0.3s linear;

    &:after {
      content: "";
      width: 9px;
      height: 8px;
      background: url("../../../images/check.png") no-repeat;
      background-size: contain;
      position: absolute;
      left: calc(50% - 4.5px);
      top: calc(50% - 4px);
      transform: scale(0);
      transition: 0.3s linear;
      border-radius: 3px;
    }
  }

  &__text {
    font-weight: 600;
  }

  & .primaryTextAnchor {
    position: relative;
    z-index: 1;
    display: inline-block;
  }
}
