h1 {
  @include mob-screen {
    font-size: em(24);
    line-height: 24px;
    letter-spacing: 0.6px;
  }
}

@for $i from 1 through 6 {
  h#{$i} {
    color: $black;
    display: inline-block;
    margin: 0px 0px 20px 0px;
  }
}
