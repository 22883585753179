.pageHeader {
  &.hasCrossOver {
    min-height: 280px;
    &--lg {
      padding-bottom: 220px;

      & + {
        .wPanel {
          margin-top: -200px;
        }
      }
    }
  }
}
.navHead {
  display: flex;
  align-items: center;

  &__back {
    margin-right: 15px;
    display: flex;
    align-items: center;
  }

  &__text {
    font-size: em(18);
    letter-spacing: 0.45px;
    line-height: 24px;
    color: $white;
    font-weight: 600;
    margin: 0px;
  }
}
.logoLink {
  display: inline-block;
  width: 190px;
  margin-bottom: 40px;

  &__in {
    width: 100%;
  }
}
.titleBlock {
  & h1 {
    color: $white;
    margin: 0px;
    font-weight: 600;
  }

  & p {
    font-size: em(14);
    letter-spacing: 0.6px;
    line-height: 24px;
    color: $white;
    margin: 0px;
  }
}
.tblock {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 0px;

  &__text {
    font-size: em(18);
    letter-spacing: 0.45px;
    line-height: 24px;
    color: $white;
    font-weight: 500;
  }

  & .iconIn {
    margin-right: 15px;
  }
}
