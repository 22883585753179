.sidebar {
  width: 100%;
  max-width: 325px;
  background: $white;
  height: 100%;
  position: fixed;
  top: 0px;
  left: -100%;
  transition: 0.3s linear;
  z-index: 2;
  padding: 45px 25px 30px 32px;

  &__logo {
    width: 100%;
    max-width: 235px;
    margin: 0 auto;
    display: block;

    &__in {
      width: 100%;
    }
  }

  &__out {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 13px;
    top: 10px;

    &:before,
    &:after {
      content: "";
      width: 20px;
      height: 4px;
      background: $black;
      position: absolute;
      left: calc(50% - 10px);
      top: calc(50% - 2px);
      border-radius: 3px;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  &__List {
    list-style-type: none;
    padding: 0px;
    margin: 45px 0px 0px 0px;

    &__item {
      font-size: em(16);
      letter-spacing: 0.4px;
      color: $black;
      line-height: 24px;
      font-weight: 400;
      padding-left: 32px;
      position: relative;
      margin: 8px 0px;
      display: inline-block;
      width: 100%;

      &:before {
        content: "";
        width: 21px;
        height: 21px;
        position: absolute;
        left: 0px;
        top: 0px;
        background: url("../../../images/check-inactive.png") no-repeat;
        background-size: contain;
      }

      &--completed {
        &:before {
          background: url("../../../images/check-active.png") no-repeat;
          background-size: contain;
        }
      }

      &--active {
        color: $primary;
        font-weight: 700;
      }
    }

    &__innerList {
      padding: 0px;
      margin: 10px 0px;

      &__text {
        margin: 10px 0px;
        color: $black;
        font-weight: 400;
      }
    }
  }

  &--active {
    left: 0px;
    transition: 0.3s linear;
  }
}
