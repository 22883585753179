.cameraView {
  width: 100%;
  height: 100%;
  position: fixed;
  background: #000000; // url("../../../images/arabic.jpg") no-repeat;
  background-size: cover;
  left: 0px;
  top: 0px;
  display: flex;
  align-items: center;
  justify-self: center;

  &--idScan {
    background: $bodyBg; // url("../../../images/id-card.jpg") no-repeat;
    background-size: cover;
    background-position: center;

    & .cameraPosition {
      margin: 0px !important;
      width: 100%;
    }

    & video {
      width: 100%;
      height: 100%;
    }
  }
}
.cameraOverlay {
  width: 100%;
  height: 100%;
  //background: url("../../../images/overlay.png") no-repeat;
  //background-size: 130%;
  background-repeat: no-repeat;
  background-position: center;
  position: fixed;
  top: 0;
  left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px;

  &--idScan {
    //background: url("../../../images/id-overlay.png") no-repeat;
    //background-size: cover;
    padding: 15px 20px;

    @include mob-screen {
      background-position: 50% 50% !important;
    }
  }

  @include mob-screen {
    background-position: 50% 50%;
  }

  &__head {
    display: flex;
    align-items: center;

    &__back {
      margin-right: 15px;
      display: flex;
      align-items: center;
    }

    &__text {
      font-size: em(18);
      letter-spacing: 0.45px;
      line-height: 24px;
      color: $white;
      font-weight: 600;
      margin: 0px;
    }
  }

  &__option {
    width: 100%;
    /*max-width: 300px;*/
    margin: 0 auto;
    text-align: center;

    &__instruction {
      margin: 0 0 10px 0px;

      & p {
        font-size: em(16);
        letter-spacing: 0.4px;
        line-height: 24px;
        color: $white;
        margin: 0px;
        text-shadow: 0px 0px 10px #000000;
      }
    }

    &__ButtonSet {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & .sub-txt {
      color: #ffffff;
      z-index: 3;
      border: 1px solid #ffffff;
      padding: 5px;
      margin-bottom: 20px;
      border-radius: 5px;
      display: inline-block;
    }

    &__image {
      width: 100%;
      max-width: 80px;
      display: none;

      @include landscape {
        display: block;
      }

      & img {
        width: 100%;
      }
    }
  }
}

.animativeIcon {
  &--left {
    & .iconIn {
      position: relative;
      animation: 1s bounceLeft linear infinite;
    }
  }

  &--right {
    & .iconIn {
      position: relative;
      animation: 1s bounceRight linear infinite;
    }
  }
}

@keyframes bounceLeft {
  0% {
    left: 0px;
  }
  50% {
    left: -20px;
  }
  100% {
    left: 0px;
  }
}

@keyframes bounceRight {
  0% {
    right: 0px;
  }
  50% {
    right: -20px;
  }
  100% {
    right: 0px;
  }
}

.cameraView.hide {
  display: none;
}

.cameraOverlay.hide {
  display: none;
}
.cameraPosition {
  width: 100%;
}
.previewImage {
  /*border: 2px solid $primary;*/
  border-radius: 5px;
  margin: 10px 0px;
}
.idpreview > div {
  text-align: center;
  float: none !important;
  margin: 0 auto;
  height: 180px;
}

.more-instrction.hide {
  display: none;
}

.edit-form.hide {
  display: none;
}

.preview-form.hide {
  display: none;
}
.cameravideoPosition {
  width: 100%;
}
.cameravideoPosition {
  & video {
    width: 100%;
    height: auto;
  }
}
.animate-instruct {
  width: 50px;
  height: 54px;
  display: block;
  margin: 0 auto;
  & img {
    width: auto;
    height: 100%;
  }
}
.liveness-gestures{

  .panel{
    margin-top: 60px;
  }

  .gestures-title{
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  }
  .gestures-ins{
    font-size: 13px;
    font-weight: 600;
    margin: 7px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .gestures-ico{
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px
  }

  .gesture-ins-cont{
    position: absolute;
    width: 100%;
    top: 50px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }
  .gesture-ins{
    color: #ffffff;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px
  }
  .gesture-timer{
    color: #ffffff;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
  }
  .gesture-arrow{
    text-align: center
  }
  .gesture-ins-anim{
    position: absolute;
    bottom: 40px;
    width: 100%;
    text-align: center;

    .gesture-ins-anim-bg{
      width: 145px;
      height:  192px;
      background: #3C3C3C80;
      margin: auto;
      border-radius: 50%;

      img{
        width: 145px;
        height:  192px;
      }
    }
  }
}
.gesture-ins-anim-bg.hide {
  display: none;
}
.cameraOverlay.liveness-gestures {
  padding: 0px;
}
.liveness-camera {
  video{
    transform: scale(-1, 1);
  }
}
.scanner-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50% 50% !important;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-content: space-around;
  flex-wrap: wrap;
  align-items: center;

}
.scanner-done-info{
  background: #00000080;
  color: #ffffff;
  height: 40px;
  border-radius: 20px;
  text-align: center;
  line-height: 40px;
  min-width: 200px;
  width: auto;
  display: inline-block;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 14px;
  font-weight: 600;

  position: absolute;
}
.scanner-overlay.liveness-gestures {
  padding: 0px;
}
.scanner-overlay.hide {
  display: none;
}
.scanner-lottie-cont{
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  transform: scaleX(-1);
  pointer-events: none;
}
.scanner-card-sample-cont{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  pointer-events: none;

  @include landscape {
    padding-right: 200px;
    /*transform: rotate(-90deg);*/
  }
  img{
    /*transform: rotate(90deg);*/
    width: 100px;
    background: #000000a6;
    padding: 10px;
    border-radius: 10px;
  }
  .card-ico{
    width: 100px;
    background: #000000a6;
    padding: 10px;
    border-radius: 10px;
  }
  .card-preview{
    width: 80%;
    opacity: 0;
    transition: opacity .5s;
    /*background: #000000a6;
    padding: 10px;
    border-radius: 10px;*/
  }

}
.id-scan-preview {
  background: rgba($color: $black, $alpha: 0.75);

}
.id-scan-preview-img {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: calc(100% - 90px);

  @include landscape {
    width: calc(100% - 200px) !important;
    max-height: none;
    align-items: center;
  }
}
.scanner-masked-overlay{
  position: absolute;
  width: calc(100% + 200px);
  height: calc(100% + 100px);
  border: 120px solid #303030;
  box-sizing: border-box;
  left: -100px;
  top: -80px;
  border-radius: 130px;
  @include landscape {
    width: calc(100% );
    height: calc(100% + 160px);
  }
}
.idscan-btn-set {
  padding: 25px;
  background: none;

  @include landscape {
    width: 200px;
    position: absolute;
    right: 0px;
    flex-direction: column;
    height: 100%;
  }

  & .btn {
    @include landscape {
      margin: 10px 0px !important;
    }
  }
}
.cameraOverlay--idUpload {
  background: #333;
}
.cameraOverlay__option {

  @include landscape {
    width: 200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 0px 30px;
    background: #303030;
  }
}
.gesture-arrow.gesture-arrow-top{
  transform: rotate(90deg);
  margin-top: 10px;
}
.gesture-arrow.gesture-arrow-bottom{
  transform: rotate(270deg);
  margin-top: 10px;
}
.btnMultiWrap {
  @include landscape {
    margin: 30px 0px;
    flex-direction: column;
  }

  & .btn {
    margin: 5px;
  }
}
.liveness-no-face{
  width: 100%;
  height: 100%;
  z-index: 20;
  position: absolute;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  .liveness-no-face-lbl{
    font-size: 15px;
    background: #00000080;
    padding: 10px;
    border-radius: 6px;
    color: #fff;
  }
}
.scanner-low-brightness{
  margin-top: 20px;
  color: #fff;
  font-size: 13px;
  background: #00000080;
  padding: 10px;
  border-radius: 6px;
}
