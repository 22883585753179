@mixin mob-screen {
  @media only screen and (max-width: 767px) {
    @content;
  }
}
@mixin large-screen {
  @media (min-width: 1024px) {
    @content;
  }
}
@mixin landscape {
  @media (orientation: landscape) {
    @content
  }
}
