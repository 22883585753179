.iconIn {
  width: 20px;
  height: 20px;
  display: inline-block;

  &--user {
    background: url("../../../images/user.png") no-repeat;
    background-size: contain;
    background-position: center;
  }

  &--mob {
    background: url("../../../images/mobile.png") no-repeat;
    background-size: contain;
    background-position: center;
  }

  &--idRed {
    height: 19px;
    width: 18px;
    background: url("../../../images/national-id.png") no-repeat;
    background-size: contain;
    background-position: center;
  }

  &--back {
    width: 17px;
    height: 17px;
    background: url("../../../images/arrow-left.png") no-repeat;
    background-size: contain;
  }

  &--play {
    width: 19px;
    height: 19px;
    background: url("../../../images/record.png") no-repeat;
    background-size: contain;
  }

  &--stop {
    width: 19px;
    height: 19px;
    background: url("../../../images/stop.png") no-repeat;
    background-size: contain;
  }

  &--scan {
    width: 18px;
    height: 18px;
    background: url("../../../images/scan-id.png") no-repeat;
    background-size: contain;
  }

  &--arrowLeft {
    width: 36px;
    height: 17px;
    background: url("../../../images/arrow.png") no-repeat;
    background-size: contain;
  }

  &--arrowRight {
    width: 36px;
    height: 17px;
    background: url("../../../images/arrow-right.png") no-repeat;
    background-size: contain;
  }

  &--video {
    width: 71px;
    height: 71px;
    background: url("../../../images/video-circle.png") no-repeat;
    background-size: contain;
  }

  &--tickRound {
    width: 71px;
    height: 71px;
    background: url("../../../images/check-circle.png") no-repeat;
    background-size: contain;
  }
  &--crossRound {
    width: 71px;
    height: 71px;
    background: url("../../../images/close-circle.png") no-repeat;
    background-size: contain;
  }

  &--hamburger {
    width: 22px;
    height: 16px;
    background: url("../../../images/hamburger-menu.png") no-repeat;
    background-size: contain;
  }

  &--image {
    width: 71px;
    height: 71px;
    background: url("../../../images/card-circle.png") no-repeat;
    background-size: contain;
  }
}
